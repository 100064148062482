import ErrorBlock from "../src/components/ErrorBlock";

export const runtime = "experimental-edge";

interface IErrorProps {
  statusCode: number;
  reason: string;
}

const ErrorData = (props: IErrorProps) => {
  const { statusCode, reason } = props;
  return <ErrorBlock statusCode={statusCode} reason={reason} redirectToauth={false} />;
};

export const getStaticProps = async ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { props: { statusCode } };
};

export default ErrorData;
